import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';

import { MapViewComponent } from './views/map-view/map-view.component';
import {
  RECAPTCHA_V3_SITE_KEY,
  RecaptchaV3Module,
  RecaptchaModule,
} from 'ng-recaptcha';
import { ColorCircleModule } from 'ngx-color/circle';

import { AppComponent } from './app.component';
import { WelcomeDialogViewComponent } from './views/welcome-dialog-view/welcome-dialog-view.component';

import { CaptchaComponent } from './components/captcha/captcha.component';
import { PopupBasicComponent } from './components/dialogs/popup-basic/popup-basic.component';
import { ReCaptchaV2Component } from './components/captcha/re-captcha-v2/re-captcha-v2.component';
import { StarRatingComponent } from './components/star-rating/star-rating.component';

import { RxStompService } from './rx-stomp.service';
import { rxStompServiceFactory } from './rx-stomp-service-factory';
import { DeviceTypeGuard } from './guards/device-type-guard.module';
import { environment } from '../environments/environment';
import { PreloaderComponent } from './components/preloader/preloader.component';
import { PageNotFoundComponent } from './views/page-not-found/page-not-found.component';
import { HeaderComponent } from './components/header/header.component';
import { ColorSketchComponent } from './components/dialogs/color-sketch/color-sketch.component';
import { DialogConfirmModule } from '@nx-workspace/dialogs/dialog-confirm';
import { LoadingModule } from './components/preloader/loading-wrapper.module';
import { PreloaderService } from './services/preloader.service';
import { MaterialModule } from '@nx-workspace/material/material-module';
import { MapModule } from '@nx-workspace/components/map';
import { API_URL, APP_CONFIG } from '@nx-workspace/static-data/options';
import * as Globals from './globals'

@NgModule({
  declarations: [
    AppComponent,
    PreloaderComponent,
    PageNotFoundComponent,
    WelcomeDialogViewComponent,
    HeaderComponent,
    CaptchaComponent,
    PopupBasicComponent,
    ReCaptchaV2Component,
    ColorSketchComponent,
    StarRatingComponent,
    MapViewComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    MaterialModule,
    RecaptchaV3Module,
    RecaptchaModule,
    ColorCircleModule,
    DialogConfirmModule,
    LoadingModule,
    TranslateModule.forRoot(),
    MapModule
  ],
  providers: [
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: CustomHttpInterceptor,
    //   multi: true,
    // },
    DeviceTypeGuard,
    {
      provide: RxStompService,
      useFactory: rxStompServiceFactory,
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptcha.siteKeyV3,
    },
    { provide: API_URL, useValue: Globals.API_URL},
    { provide: APP_CONFIG, useValue: environment },
    PreloaderService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
