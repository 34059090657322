import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ICustomer, IUser, IAvatar } from '@nx-workspace/interfaces';

@Injectable({
  providedIn: 'root',
})
export class AuthStorageService {
  private readonly authDataKey: string = 'auth-data';
  authData$: BehaviorSubject<IUser | null> = new BehaviorSubject<IUser | null>(null);

  constructor() {}

  getAuthData(): IUser {
    return JSON.parse(localStorage.getItem(this.authDataKey) as string);
  }

  setAuthData(authData: IUser): void {
    localStorage.setItem(this.authDataKey, JSON.stringify(authData));
    this.authData$.next(authData);
  }

  clearAllData(): void {
    this.eraseAuthData();
  }

  private eraseAuthData(): void {
    localStorage.removeItem(this.authDataKey);
    this.authData$.next(null);
  }

  getCurrentUser(): IUser {
    return this.getAuthData();
  }

  getCurrentId(): number | null {
    const data: IUser = this.getAuthData();
    return data ? data.id : null;
  }

  getCurrentRole(): any {
    const data: IUser = this.getAuthData();
    return data ? data.userRole : null;
  }

  isAnyAdmin(): boolean {
    return this.isRootRole() || this.isAdminRole();
  }

  isRootRole(): boolean {
    return this.getCurrentRole() === 'ROLE_ROOT';
  }

  isAdminRole(): boolean {
    return this.getCurrentRole() === 'ROLE_CUSTOMER_ADMIN';
  }

  isExternalUser(): boolean {
    return this.getCurrentRole() === 'ROLE_EXTERNAL_USER';
  }

  isViewerRole(): boolean {
    return this.getCurrentRole() === 'ROLE_VIEW';
  }

  isServiceTechnicianRole(): boolean {
    return this.getCurrentRole() === 'servicetechnician';
  }

  isEngineerRole(): boolean {
    return this.getCurrentRole() === 'CONTROL_ENGINEER';
  }

  hasAccessTalq(): boolean {
    const data: IUser = this.getAuthData();
    return !!data.currentCustomer?.allowTalq;
  }

  getCurrentCustomer(): ICustomer | null | undefined {
    const data: IUser = this.getAuthData();
    return data ? data.currentCustomer : null;
  }

  getCurrentAvatar(): IAvatar | null {
    const data: IUser = this.getAuthData();
    return data ? data.avatar : null;
  }
}
