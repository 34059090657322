// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
declare const require: any;

export const environment = {
  appVersion: require('../../../../package.json').version + '-dev',
  production: false,
  baseUrl: 'https://control.c2smartlight.com', //'https://demo.c2sl.net', // 'https://188.166.82.164'
  recaptcha: {
    siteKeyV3: '6LczsIAfAAAAAKOlwzccfcjv1O6MVvzm79HDfsi9',
    siteKeyV2: '6LfIw5ofAAAAAJsfi7ZwLQq9rnB-LBQRZotoG40J',
  },
  appName: 'streetlight'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
