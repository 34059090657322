import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IUnit } from '../interfaces';
import { API_URL } from '@nx-workspace/static-data/options';

@Injectable({
  providedIn: 'root',
})
export class UnitsService {
  constructor(private http: HttpClient) {}

  API_URL = inject(API_URL);

  getUnits(): Observable<IUnit> {
    return this.http.get<IUnit>(`${this.API_URL.UNITS.GET.DEVICES}`);
  }

  getUnitById(id: number | string): Observable<IUnit> {
    return this.http.get<IUnit>(`${this.API_URL.UNITS.GET.DEVICES}${id}`);
  }

  updateColor(unit: IUnit): Observable<IUnit> {
    return this.http.patch<IUnit>(
      `${this.API_URL.UNITS.GET.DEVICES}${unit.id}`,
      unit
    );
  }
}
