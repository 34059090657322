// @ts-nocheck
import * as L from 'leaflet';

export const RefreshControl = L.Control.extend({
  options: {
    position: 'topleft',
    title: 'Refresh',
    forceSeparateButton: false,
  },

  onAdd: function (map) {
    let className = 'leaflet-control--refresh';
    let container;
    let content = '';

    if (map.zoomControl && !this.options.forceSeparateButton) {
      container = map.zoomControl._container;
    } else {
      container = L.DomUtil.create('div', 'leaflet-bar');
    }

    if (this.options.content) {
      content = this.options.content;
    } else {
      className += ' refresh-icon';
    }

    this._createButton(
      this.options.title,
      className,
      content,
      container,
      this.emit,
      this
    );
    this._map.refreshControl = this;

    this._map.on('refreshPressed', function () {}, this);

    return container;
  },

  onRemove: function (map) {
    L.DomEvent.off(this.link, 'click, touchstart', L.DomEvent.stopPropagation)
      .off(this.link, 'click, touchstart', L.DomEvent.preventDefault)
      .off(this.link, 'click, touchstart', this.emit, this);
  },

  _createButton: function (title, className, content, container, fn, context) {
    this.link = L.DomUtil.create('a', className, container);
    this.link.href = '#';
    this.link.title = title;
    this.link.innerHTML = content;

    this.link.setAttribute('role', 'button');
    this.link.setAttribute('aria-label', title);

    // L.DomEvent
    //   .disableClickPropagation(this.link)
    //   .on(this.link, 'click, touchstart', L.DomEvent.stopPropagation)
    //   .on(this.link, 'click, touchstart', L.DomEvent.preventDefault)
    //   .on(this.link, 'click, touchstart', fn, context);

    L.DomEvent.disableClickPropagation(this.link)
      .on(this.link, 'click', L.DomEvent.stop)
      .on(this.link, 'click', fn, context)
      .on(this.link, 'click', this._refocusOnMap, context);

    return this.link;
  },

  emit: function () {
    let map = this._map;
    map.fire('refreshPressed');
  },
});

L.Map.addInitHook(function () {
  if (this.options.refreshControl) {
    this.addControl(L.control.refresh(this.options.refreshControlOptions));
  }
});

L.control.refresh = function (options) {
  return new RefreshControl(options);
};

export const LeafletLassoControl = L.Control.extend({
  options: {
    position: 'topleft',
    title: 'Lasso',
    forceSeparateButton: false,
  },

  onAdd: function (map) {
    let className = 'leaflet-control--lasso';
    let container;
    let content = '';

    if (map.zoomControl && !this.options.forceSeparateButton) {
      container = map.zoomControl._container;
    } else {
      container = L.DomUtil.create('div', 'leaflet-bar');
    }

    if (this.options.content) {
      content = this.options.content;
    } else {
      className += ' lasso-icon';
    }

    this._createButton(
      this.options.title,
      className,
      content,
      container,
      this.emit,
      this
    );
    this._map.leafletLassoControl = this;

    // this._map.on('LassoPressed', function () {}, this);

    return container;
  },

  onRemove: function (map) {
    L.DomEvent.off(this.link, 'click', L.DomEvent.stopPropagation)
      .off(this.link, 'click', L.DomEvent.preventDefault)
      .off(this.link, 'click', this.emit, this);
  },

  _createButton: function (title, className, content, container, fn, context) {
    this.link = L.DomUtil.create('a', className, container);
    this.link.href = '#';
    this.link.title = title;
    this.link.innerHTML = content;

    this.link.setAttribute('role', 'button');
    this.link.setAttribute('aria-label', title);

    L.DomEvent.on(this.link, 'click', L.DomEvent.stopPropagation)
      .on(this.link, 'click', L.DomEvent.preventDefault)
      .on(this.link, 'click', fn, context);

    return this.link;
  },

  emit: function () {
    let map = this._map;
    map.fire('lassoPressed');
  },
});

L.Map.addInitHook(function () {
  if (this.options.leafletLassoControl) {
    this.addControl(
      L.control.leafletLasso(this.options.leafletLassoControlOptions)
    );
  }
});

L.control.leafletLasso = function (options) {
  return new LeafletLassoControl(options);
};

export const LocationControl = L.Control.extend({
  options: {
    position: 'topleft',
    title: 'Location',
    forceSeparateButton: false,
  },

  onAdd: function (map) {
    let className = 'leaflet-control--location';
    let container;
    let content = '';

    if (map.zoomControl && !this.options.forceSeparateButton) {
      container = map.zoomControl._container;
    } else {
      container = L.DomUtil.create('div', 'leaflet-bar');
    }

    if (this.options.content) {
      content = this.options.content;
    } else {
      className += ' location-icon';
    }

    this._createButton(
      this.options.title,
      className,
      content,
      container,
      this.emit,
      this
    );
    this._map.locationControl = this;

    this._map.on('locationPressed', function () {}, this);

    return container;
  },

  onRemove: function (map) {
    L.DomEvent.off(this.link, 'touchstart', L.DomEvent.stopPropagation)
      .off(this.link, 'touchstart', L.DomEvent.preventDefault)
      .off(this.link, 'touchstart', this.emit, this);
  },

  _createButton: function (title, className, content, container, fn, context) {
    this.link = L.DomUtil.create('a', className, container);
    this.link.href = '#';
    this.link.title = title;
    this.link.innerHTML = content;

    this.link.setAttribute('role', 'button');
    this.link.setAttribute('aria-label', title);

    // L.DomEvent
    //   .on(this.link, 'touchstart', L.DomEvent.stopPropagation)
    //   .on(this.link, 'touchstart', L.DomEvent.preventDefault)
    //   .on(this.link, 'touchstart', fn, context);

    L.DomEvent.disableClickPropagation(this.link)
      .on(this.link, 'click', L.DomEvent.stop)
      .on(this.link, 'click', fn, context)
      .on(this.link, 'click', this._refocusOnMap, context);

    return this.link;
  },

  emit: function (event) {
    let map = this._map;
    map.fire('locationPressed');
  },
});

L.Map.addInitHook(function () {
  if (this.options.locationControl) {
    this.addControl(L.control.location(this.options.locationControlOptions));
  }
});

L.control.location = function (options) {
  return new LocationControl(options);
};

export const ChannelsControl = L.Control.extend({
  options: {
    position: 'topright',
    title: 'View Channels',
    forceSeparateButton: false,
  },

  onAdd: function (map) {
    let className = 'leaflet-control--channels';
    let container;
    let content = '';

    if (map.zoomControl && !this.options.forceSeparateButton) {
      container = map.zoomControl._container;
    } else {
      container = L.DomUtil.create('div', 'leaflet-control-layers');
    }

    if (this.options.content) {
      content = this.options.content;
    } else {
      className += ' channels-icon';
    }

    this._createButton(
      this.options.title,
      className,
      content,
      container,
      this.emit,
      this
    );
    this._map.channelsControl = this;

    this._map.on('channelsPressed', function () {}, this);

    return container;
  },

  onRemove: function (map) {
    L.DomEvent.off(this.link, 'click, touchstart', L.DomEvent.stopPropagation)
      .off(this.link, 'click, touchstart', L.DomEvent.preventDefault)
      .off(this.link, 'click, touchstart', this.emit, this);
  },

  _createButton: function (title, className, content, container, fn, context) {
    this.link = L.DomUtil.create('a', className, container);
    this.link.href = '#';
    this.link.title = title;
    this.link.innerHTML = content;

    this.link.setAttribute('role', 'button');
    this.link.setAttribute('aria-label', title);

    // L.DomEvent
    //   .disableClickPropagation(this.link)
    //   .on(this.link, 'click, touchstart', L.DomEvent.stopPropagation)
    //   .on(this.link, 'click, touchstart', L.DomEvent.preventDefault)
    //   .on(this.link, 'click, touchstart', fn, context);

    L.DomEvent.disableClickPropagation(this.link)
      .on(this.link, 'click', L.DomEvent.stop)
      .on(this.link, 'click', fn, context)
      .on(this.link, 'click', this._refocusOnMap, context);

    return this.link;
  },

  emit: function () {
    this.link.title = L.DomUtil.hasClass(this.link, 'active') ? this.options.title : this.options.titleCancel;
    L.DomUtil.hasClass(this.link, 'active') ? L.DomUtil.removeClass(this.link, 'active') : L.DomUtil.addClass(this.link, 'active');
    
    let map = this._map;
    map.fire('channelsPressed');
  },
});

L.Map.addInitHook(function () {
  if (this.options.channelsControl) {
    this.addControl(L.control.channels(this.options.channelsControlOptions));
  }
});

L.control.channels = function (options) {
  return new ChannelsControl(options);
};