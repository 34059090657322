import * as L from 'leaflet';

interface IMarkerSettings {
  mapIconUrl?: string;
  borderColor?: string;
  innerColor?: string;
  strokeColor?: string;
  strokeWidth?: number;
  iconHeight?: number;
  iconWidth?: number;
  iconAnchor?: L.PointExpression;
  popupAnchor?: L.PointExpression;
}

export const lumo_icon_rgb = `<?xml version="1.0" encoding="UTF-8"?>
  <svg width="{iconWidth}px" height="{iconHeight}px" viewBox="0 0 43 50" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <linearGradient id="linearColors" x1="0" y1="0" x2="1" y2="1">
      <stop offset="10%" stop-color="#ff0000"></stop>
      <stop offset="30%" stop-color="#ffff00"></stop>
      <stop offset="50%" stop-color="#00ff00"></stop>
      <stop offset="70%" stop-color="#00ffff"></stop>
      <stop offset="80%" stop-color="#0000ff"></stop>
      <stop offset="100%" stop-color="#ff00ff"></stop>
    </linearGradient>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Group-6-Copy-42" transform="translate(1.000000, 1.000000)">
          <path d="M20.2239059,0.940988235 C9.05496471,0.940988235 -9.41176471e-05,9.99604706 -9.41176471e-05,21.1649882 C-9.41176471e-05,27.1612235 2.62484706,32.5315765 6.76978824,36.2351059 L6.73778824,36.2351059 L19.5170824,47.3240471 C19.9217882,47.7278118 20.5759059,47.7278118 20.9806118,47.3240471 L33.7119059,36.2351059 L33.6780235,36.2351059 C37.8239059,32.5315765 40.4488471,27.1612235 40.4488471,21.1649882 C40.4488471,9.99604706 31.3937882,0.940988235 20.2239059,0.940988235" id="Fill-1" fill="url(#linearColors)" stroke="{strokeColor}" stroke-width="{strokeWidth}"></path>
          <path d="M34.8976,21.1652706 C34.8976,29.2697412 28.3291294,35.8391529 20.2237176,35.8391529 C12.1201882,35.8391529 5.55077647,29.2697412 5.55077647,21.1652706 C5.55077647,13.0617412 12.1201882,6.49232941 20.2237176,6.49232941 C28.3291294,6.49232941 34.8976,13.0617412 34.8976,21.1652706" id="Fill-4-brightness" fill="{innerColor}"></path>
      </g>
    </g>
  </svg>`;

export const lumo_icon_temperature = `<?xml version="1.0" encoding="UTF-8"?>
  <svg width="{iconWidth}px" height="{iconHeight}px" viewBox="0 0 43 50" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Group-6-Copy-42" transform="translate(1.000000, 1.000000)">
          <path d="M20.2239059,0.940988235 C9.05496471,0.940988235 -9.41176471e-05,9.99604706 -9.41176471e-05,21.1649882 C-9.41176471e-05,27.1612235 2.62484706,32.5315765 6.76978824,36.2351059 L6.73778824,36.2351059 L19.5170824,47.3240471 C19.9217882,47.7278118 20.5759059,47.7278118 20.9806118,47.3240471 L33.7119059,36.2351059 L33.6780235,36.2351059 C37.8239059,32.5315765 40.4488471,27.1612235 40.4488471,21.1649882 C40.4488471,9.99604706 31.3937882,0.940988235 20.2239059,0.940988235" id="Fill-1" fill="{borderColor}" stroke="{strokeColor}" stroke-width="{strokeWidth}"></path>
          <path d="M34.8976,21.1652706 C34.8976,29.2697412 28.3291294,35.8391529 20.2237176,35.8391529 C12.1201882,35.8391529 5.55077647,29.2697412 5.55077647,21.1652706 C5.55077647,13.0617412 12.1201882,6.49232941 20.2237176,6.49232941 C28.3291294,6.49232941 34.8976,13.0617412 34.8976,21.1652706" id="Fill-4" fill="{innerColor}"></path>
      </g>
    </g>
  </svg>`;

export const lumo = {
  iconHeight: 28,
  iconWidth: 34,
  iconAnchor: [14, 28] as L.PointExpression,
  popupAnchor: [0, -28] as L.PointExpression,
};

export const lumoSelected = {
  iconHeight: 36,
  iconWidth: 43,
  iconAnchor: [18, 36] as L.PointExpression,
  popupAnchor: [0, -36] as L.PointExpression,
};

export const borderColor = {
  error: '#DC2C2C',
  warning: '#EE7B0D',
  ok: '#FFFFFF',
  maintenance: '#1B83DD',
};

export enum GroupStatus {
  inGroup,
  remove,
  single,
}

export const temperatureColor = {
  warm: '#fef189',
  neutral: '#fefce8',
  cold: '#e0f2fe',
};

export const strokeColor = '#4BD991';

// tslint:disable-next-line:max-line-length
export function getIcon(
  item: any,
  showStatus: boolean = true,
  selectAndChangeSize: boolean = false,
  group?: GroupStatus
): L.DivIcon {
  const iconSettings: IMarkerSettings = {};
  iconSettings.mapIconUrl = lumo_icon_rgb;
  iconSettings.borderColor = borderColor.ok;
  iconSettings.innerColor = item.color;
  iconSettings.popupAnchor = lumoSelected.popupAnchor;
  iconSettings.iconWidth = 20;
  iconSettings.iconHeight = 24;
  iconSettings.iconAnchor = [10, 28] as L.PointExpression;
  iconSettings.strokeColor = 'none';
  iconSettings.strokeWidth = 0;

  if (item.deviceType === 1) {
    iconSettings.mapIconUrl = lumo_icon_temperature;
    if (item.on === '0') {
      iconSettings.innerColor = '#babbbb';
    } else {
      switch (item.color) {
        case '#1':
          iconSettings.innerColor = temperatureColor.warm;
          break;
        case '#2':
          iconSettings.innerColor = temperatureColor.neutral;
          break;
        case '#3':
          iconSettings.innerColor = temperatureColor.cold;
          break;
      }
    }
  }

  return L.divIcon({
    className: 'leaflet-data-marker',
    html: L.Util.template(iconSettings.mapIconUrl as string, iconSettings),
    iconAnchor: iconSettings.iconAnchor,
    popupAnchor: iconSettings.popupAnchor,
  });

  // switch (selectAndChangeSize) {
  //   case false:
  //     iconSettings.strokeColor = 'none';
  //     iconSettings.strokeWidth = 0;
  //     if (item.deviceType.id === 'GATEWAY') {
  //       iconSettings.iconWidth = c2cu.iconHeight;
  //       iconSettings.iconHeight = c2cu.iconWidth;
  //       iconSettings.iconAnchor = c2cu.iconAnchor;
  //     } else {
  //       iconSettings.iconWidth = lumo.iconHeight;
  //       iconSettings.iconHeight = lumo.iconWidth;
  //       iconSettings.iconAnchor = lumo.iconAnchor;
  //     }
  //     break;
  //   case true:
  //     iconSettings.strokeColor = strokeColor;
  //     iconSettings.strokeWidth = 3;
  //     if (item.deviceType.id === 'GATEWAY') {
  //       iconSettings.iconWidth = c2cuSelected.iconHeight;
  //       iconSettings.iconHeight = c2cuSelected.iconWidth;
  //       iconSettings.iconAnchor = c2cuSelected.iconAnchor;
  //     } else {
  //       iconSettings.iconWidth = lumoSelected.iconHeight;
  //       iconSettings.iconHeight = lumoSelected.iconWidth;
  //       iconSettings.iconAnchor = lumoSelected.iconAnchor;
  //     }
  //     break;
  // }

  // switch (item.deviceType.id) {
  //   case 'LUMO':
  //   case 'LUCONT':
  //     iconSettings.mapIconUrl = lumo_icon;
  //     iconSettings.popupAnchor = lumoSelected.popupAnchor;
  //     break;
  //   case 'GATEWAY':
  //     iconSettings.mapIconUrl = c2cu_icon;
  //     iconSettings.popupAnchor = c2cuSelected.popupAnchor;
  //     break;
  // }

  // if (showStatus) {
  //   switch (item.deviceState.status) {
  //     case 'alarm':
  //       iconSettings.borderColor = borderColor.error;
  //       break;
  //     case 'maintenance':
  //       iconSettings.borderColor = borderColor.maintenance;
  //       break;
  //     default:
  //       iconSettings.borderColor = borderColor.ok;
  //       break;
  //   }

  //   switch (item.deviceState.active) {
  //     case 'on':
  //       iconSettings.innerColor = statusColor.on_100;
  //       break;
  //     case 'off':
  //       iconSettings.innerColor = statusColor.off;
  //       break;
  //   }
  // } else {
  //   if (item.deviceType.id === 'GATEWAY') {
  //     iconSettings.iconWidth = c2cu.iconHeight;
  //     iconSettings.iconHeight = c2cu.iconWidth;
  //     iconSettings.iconAnchor = c2cu.iconAnchor;
  //     iconSettings.popupAnchor = c2cu.popupAnchor;
  //   } else {
  //     iconSettings.iconWidth = lumo.iconHeight;
  //     iconSettings.iconHeight = lumo.iconWidth;
  //     iconSettings.iconAnchor = lumo.iconAnchor;
  //     iconSettings.popupAnchor = lumo.popupAnchor;
  //   }
  //   iconSettings.borderColor = borderColor.ok;
  //   switch (group) {
  //     case GroupStatus.inGroup:
  //       iconSettings.strokeColor = strokeColor;
  //       iconSettings.innerColor = strokeColor;
  //       break;
  //     case GroupStatus.remove:
  //       iconSettings.strokeColor = borderColor.error;
  //       iconSettings.innerColor = borderColor.error;
  //       break;
  //     default:
  //       iconSettings.strokeColor = borderColor.ok;
  //       iconSettings.innerColor = statusColor.off;
  //   }
  // }

  // return L.divIcon({
  //   className: 'leaflet-data-marker',
  //   html: L.Util.template(iconSettings.mapIconUrl as string, iconSettings),
  //   iconAnchor: iconSettings.iconAnchor,
  //   popupAnchor: iconSettings.popupAnchor
  // });
}
