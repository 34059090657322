import { Component, OnInit } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { TranslateService } from '@ngx-translate/core';
import lang_EN from '../assets/i18n/en.json';
import lang_FI from '../assets/i18n/fi.json';
import lang_SV from '../assets/i18n/sv.json';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(private translate: TranslateService, private titleService: Title) {
    translate.setTranslation('EN', lang_EN);
    translate.setTranslation('FI', lang_FI);
    translate.setTranslation('SV', lang_SV);
    translate.setDefaultLang('EN');

    if (localStorage.getItem('locale')) {
      const browserLang = localStorage.getItem('locale') || 'EN';
      translate.use(browserLang);
    } else {
      let browserLang = 'EN';
      if (/^fi\b/.test(navigator.language)) {
        browserLang = 'FI';
      } else if (/^sv\b/.test(navigator.language)) {
        browserLang = 'SV';
      }
      localStorage.setItem('locale', browserLang);
      translate.use(browserLang);
    }

    titleService.setTitle(translate.instant('TITLE'));
  }

  ngOnInit(): void {
    console.log('Version: %c ' + environment.appVersion, 'color: lime;');
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    window.addEventListener('resize', () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
  }
}
