import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogConfig } from '@angular/material/dialog';
import { EventInput } from 'fullcalendar';

export const emailValidator =
  /^([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;
export const phoneValidator = /^\+[0-9]{7,15}$/;
export const passwordValidator =
  '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z\\d]{8,64}$';
export const passExpValidator = /^[0-9]{1,5}$/;
export const loginValidator = /^[a-zA-Z\d\s:]{4,30}$/;
export const nameValidator = /^.{2,30}$/;
export const coordValidator = /^(\-?[0-9])+(\.[0-9]+)*$/;

export let colorSets = {
  name: 'custom',
  selectable: true,
  group: 'Ordinal',
  domain: [
    '#87cefa',
    '#efb66d',
    '#91ee91',
    '#9370db',
    '#fa8787',
    '#87fae1',
    '#fa87ec',
    '#f8fa87',
  ],
};

export function PasswordsMatch(
  controlName: string,
  matchingControlName: string
) {
  return (formGroup: UntypedFormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors['mustMatch']) {
      return;
    }

    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}

export function getTimeZoneName() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export function formatDate(value: Date) {
  if (value instanceof Date) {
    let formatOptions: Intl.DateTimeFormatOptions;
    if (value.getSeconds() !== 0) {
      formatOptions = { second: '2-digit' };
    } else if (value.getMinutes() !== 0) {
      formatOptions = { hour: '2-digit', minute: '2-digit', hour12: false };
    } else if (value.getHours() !== 0) {
      formatOptions = { hour: '2-digit', minute: '2-digit', hour12: false };
    } else if (value.getDate() !== 1) {
      formatOptions =
        value.getDay() === 0
          ? { month: 'short', day: '2-digit' }
          : { weekday: 'short', day: '2-digit' };
    } else if (value.getMonth() !== 0) {
      formatOptions = { month: 'long' };
    } else {
      formatOptions = { year: 'numeric' };
    }

    const locale = localStorage.getItem('locale') || 'en';
    return new Intl.DateTimeFormat(locale, formatOptions).format(value);
  } else {
    return value;
  }
}

export function graphFormatDate(date: string) {
  const convertedDate = new Date(date);
  const weekday = new Array(7);
  weekday[0] = 'Sun';
  weekday[1] = 'Mon';
  weekday[2] = 'Tue';
  weekday[3] = 'Wed';
  weekday[4] = 'Thu';
  weekday[5] = 'Fri';
  weekday[6] = 'Sat';

  const eee = weekday[convertedDate.getDay()];
  let dd = convertedDate.getDate().toString();
  let mm = (convertedDate.getMonth() + 1).toString(); //January is 0!

  if (+dd < 10) {
    dd = '0' + dd;
  }
  if (+mm < 10) {
    mm = '0' + mm;
  }
  return eee + ', ' + dd + '.' + mm;
}

export function toISOFullDate(date: Date) {
  try {
    let dd = date.getDate().toString();
    let mm = (date.getMonth() + 1).toString();

    if (+dd < 10) {
      dd = '0' + dd;
    }
    if (+mm < 10) {
      mm = '0' + mm;
    }

    return date.getFullYear() + '-' + mm + '-' + dd;
  } catch (error) {
    return '';
  }
}

// operations with date
export function removeDays(date: string | number | Date, days: number) {
  const result = new Date(date);
  result.setDate(result.getDate() - days);
  return result;
}

export function addDays(date: string | number | Date, days: number) {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export function wrapString(text: string, length: number = 400): string {
  if (text.length > length) {
    return text.substr(0, length).trim() + '...';
  }
  return text;
}

// validate Form
export function validateForm(formGroup: UntypedFormGroup): void {
  Object.keys(formGroup.controls).forEach((field) => {
    const control = formGroup.get(field);
    if (control instanceof UntypedFormControl) {
      control.markAsTouched({ onlySelf: true });
    } else if (control instanceof UntypedFormGroup) {
      validateForm(control);
    }
  });
}

// *ngFor optimization
export function trackItem(index: number, item: any): any {
  return item ? item.id : null;
}

// Create day time
export function createDayTime(): Array<string> {
  const xAxisTicks: Array<string> = [];
  const quarterHours = [
    '00',
    '05',
    '10',
    '15',
    '20',
    '25',
    '30',
    '35',
    '40',
    '45',
    '50',
    '55',
  ];
  for (let i = 12; i < 24; i++) {
    for (let j = 0; j < 12; j++) {
      xAxisTicks.push(`${i}:${quarterHours[j]}:00`);
    }
  }
  for (let i = 0; i < 12; i++) {
    for (let j = 0; j < 12; j++) {
      xAxisTicks.push(`${i < 10 ? '0' + i : i}:${quarterHours[j]}:00`);
    }
  }
  return xAxisTicks;
}

// Remove event with similar start date
export function removeEvent(events: EventInput[]): void {
  return events
    .reduceRight((acc, n) => {
      const i = acc['findIndex']((m: any) => m.start === n.start);
      if (!~i) {
        acc['push'](n);
        if (~i) {
          acc['splice'](i, 1);
        }
      }
      return acc;
    }, [])
    ['reverse']();
}

// Format date to dd-mm-yyyy
export function formatDatetoString(date: Date): string {
  if (typeof date === 'string') {
    return date;
  }
  const mm = date.getMonth() + 1;
  const dd = date.getDate();
  return [
    date.getFullYear(),
    (mm > 9 ? '' : '0') + mm,
    (dd > 9 ? '' : '0') + dd,
  ].join('-');
}

export const dialogConfig: MatDialogConfig = {
  autoFocus: '_disable_',
};

export function appCheck(firstAppName: string, secondAppName: string ): boolean {
   return firstAppName === secondAppName; 
}
