import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'loading-wrapper',
  templateUrl: 'loading-wrapper.component.html',
  styleUrls: ['loading-wrapper.component.scss'],
})
export class LoadingWrapperComponent implements OnChanges {
  @Input() loading?: Promise<any>;
  @Input() message?: string;

  isLoading: boolean | undefined;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['loading']) {
      if (_.isUndefined(this.loading) || _.isBoolean(this.loading)) {
        this.isLoading = !!this.loading;
      } else {
        this.isLoading = true;
        const loading = this.loading;
        loading.then(
          () => this.onLoadingCompleted(loading),
          () => this.onLoadingCompleted(loading)
        );
      }
    }
  }

  private onLoadingCompleted(loading: Promise<any>) {
    if (this.loading === loading) {
      this.isLoading = false;
    }
  }
}
