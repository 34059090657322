<ng-template [ngIf]="currentState === popupState.main">
  <div class="modal-window__title">
    <div class="modal-window__title__text">
      <h2 mat-dialog-title>{{ (isRgb(data.device) ? 'CHANGE_COLOR.TITLE' : 'CHANGE_COLOR.TITLE_TEMPERATURE') | translate }}</h2>
      <p>{{ 'CHANGE_COLOR.DEVICE_NAME' | translate }}: {{ data.device.address }}</p>
    </div>
    <mat-icon inline mat-dialog-close>close</mat-icon>
  </div>
  <ng-template [ngIf]="isRgb(data.device) || data.device.on === '1'">
    <mat-dialog-content>
      <p>
        {{
          (isRgb(data.device)
            ? 'CHANGE_COLOR.DESCRIPTION'
            : 'CHANGE_COLOR.DESCRIPTION_TEMPERATURE'
          ) | translate
        }}
      </p>
      <p>{{ 'CHANGE_COLOR.SELECT_COLOR' | translate }}</p>
      <app-color-sketch
        [color]="getColor(data.device)"
        [isRgb]="isRgb(data.device)"
        (onUpdateColor)="onSelectedColor($event)"
      ></app-color-sketch>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-raised-button mat-dialog-close>
        {{ 'GLOBAL.CANCEL' | translate }}
      </button>
      <button
        mat-raised-button
        color="primary"
        (click)="onApplyColor()"
        [disabled]="!selectedColor"
      >
        {{ 'GLOBAL.APPLY' | translate }}
      </button>
    </mat-dialog-actions>
  </ng-template>
  <ng-template [ngIf]="!isRgb(data.device) && data.device.on === '0'">
    <mat-dialog-content>
      <p>{{ 'CHANGE_COLOR.DISABLED' | translate }}</p>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-raised-button mat-dialog-close color="primary">
        {{ 'GLOBAL.OK' | translate }}
      </button>
    </mat-dialog-actions>
  </ng-template>
</ng-template>

<ng-template
  [ngIf]="currentState === popupState.captcha"
  class="captcha-container"
>
  <app-re-captcha-v2
    class="captcha"
    (onUpdateCaptchaToken)="onCaptchaTrue($event)"
  ></app-re-captcha-v2>
</ng-template>

<ng-template [ngIf]="currentState === popupState.success">
  <div class="done">
    <div class="done__icon">
      <mat-icon class="material-icons color_green">done</mat-icon>
    </div>
    <div class="done__text">
      <h4>{{ 'DONE.TITLE' | translate }}</h4>
    </div>
    <div class="done__button">
      <button mat-raised-button color="primary" (click)="doneButtonClicked()">
        {{ 'GLOBAL.OK' | translate }}
      </button>
    </div>
  </div>
</ng-template>

<ng-template [ngIf]="currentState === popupState.error">
  <div class="done">
    <div class="done__icon cancel">
      <mat-icon class="material-icons color_red">clear</mat-icon>
    </div>
    <div class="done__text">
      <h4>{{ 'CAPTCHA.WRONG' | translate }}</h4>
    </div>
    <div class="done__button">
      <button mat-raised-button color="primary" (click)="doneButtonClicked()">
        {{ 'GLOBAL.OK' | translate }}
      </button>
    </div>
  </div>
</ng-template>

<ng-template [ngIf]="currentState === popupState.survey">
  <div class="modal-window__title">
    <div class="modal-window__title__text">
      <h2 mat-dialog-title>{{ 'SURVEY.TITLE' | translate }}</h2>
      <p>{{ 'SURVEY.SUBTITLE' | translate }}</p>
    </div>
    <mat-icon inline mat-dialog-close>close</mat-icon>
  </div>
  <ng-container *ngIf="currentSurveyState === surveyState.main">
    <mat-dialog-actions>
      <button mat-stroked-button (click)="survey(surveyChoice.never)">
        {{ 'SURVEY.NO' | translate }}
      </button>
      <button
        mat-stroked-button
        color="primary"
        (click)="survey(surveyChoice.later)"
      >
        {{ 'SURVEY.LATER' | translate }}
      </button>
      <button
        mat-flat-button
        color="primary"
        (click)="survey(surveyChoice.rate)"
      >
        {{ 'SURVEY.RATE' | translate }}
      </button>
    </mat-dialog-actions>
  </ng-container>
  <ng-container *ngIf="currentSurveyState === surveyState.survey">
    <mat-dialog-content class="survey">
      <mat-star-rating
        [rating]="rating"
        (ratingUpdated)="rating = $event"
      ></mat-star-rating>
      <mat-form-field appearance="outline">
        <!-- <mat-label>Leave a comment</mat-label> -->
        <textarea
          matInput
          [(ngModel)]="surveyTextArea"
          placeholder="{{ 'SURVEY.TEXTAREA' | translate }}"
        ></textarea>
      </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button
        mat-stroked-button
        color="primary"
        (click)="survey(surveyChoice.later)"
      >
        {{ 'GLOBAL.CANCEL' | translate }}
      </button>
      <button
        mat-flat-button
        color="primary"
        [disabled]="!rating"
        (click)="submitSurvey()"
      >
        {{ 'GLOBAL.SUBMIT' | translate }}
      </button>
    </mat-dialog-actions>
  </ng-container>
  <!-- <div class="done">
        <div class="done__icon cancel">
            <mat-icon class="material-icons color_red">clear</mat-icon>
        </div>
        <div class="done__text">
            <h4>{{ 'GLOBAL.CANCEL' | translate }}</h4>
        </div>
        <div class="done__button">
            <button mat-raised-button color="primary">{{ 'GLOBAL.OK' | translate }}</button>
        </div>
    </div> -->
</ng-template>
