export enum SettingsTabsForSingleUnit {
  founds,
  commands,
  events,
  history,
  alerts,
  graphs,
  comments,
  motionSensor,
  dali,
  fieldController,
  dynamicControl,
  dynamicLevels,
}

export enum WaterpumpsSettingsTabsForSingleUnit {
  events,
  history,
  alerts,
  graphs,
  comments,
  fieldController,
}

export enum SettingsTabsForSingleTalqDevice {
  details,
  commands,
  lampMonitor,
  sensors,
  events,
  alarms,
}

export enum SettingsTabsForAllUnits {
  founds,
  commands,
  summary,
}

export enum SettingsTabsForAllTalq {
  founds,
  gateway,
  summary,
  commands,
}

export enum SettingsTabsForSingleTalqGateway {
  details,
  commands,
}
